/*********************************************
:: Product Module
********************************************/
article.product {
    position: relative;
    margin: 26px 0 15px;

    text-align: center;

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;

        width: 0%;
        height: 4px;
        margin: auto;

        background: $color-2;
        border-radius: 2px;
        transition: all 0.5s ease-in-out;
    }

    .image {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 170px;
        height: 170px;
        margin: 0 auto 20px;

        transition: all 0.5s ease;

        img {
            display: block;
            width: 75%;
            transform: scale(0.9);
            transition: 0.3s ease all;
        }
    }

    h3 {
        width: 100%;
        min-height: 45px;
        margin: 0 0 5px;

        font-size: 15px;
        font-weight: 300;
        line-height: 21px;
        color: #0d0d0d;

        transition: all 0.5s ease-in-out;
    }
}

article:hover {
    &:after {
        width: 100%;
        background: $color-1;
    }

    .image {
        img {
            transform: scale(1);
        }
    }

    h3 {
        color: $color-1;
    }
}
/********************************************/

