/*********************************************
:: Contact Page                   
********************************************/
.page-contact {

    .tabs-container {
        position: relative;
        padding: 35px 0;

        font-size: 0;

        @include mappy-bp(medium) {
            padding: 65px 0 63px;
        }

        &:before {
            content: '';
            position: absolute;
            top: calc(100% - 35px);
            left: 0;
            width: 100%;
            height: 1px;

            background: #CCCCCC;

            @include mappy-bp(medium) {
                top: calc(100% - 63px);
            }
        }

        a {
            position: relative;
            display: inline-block;
            width: auto;
            height: 40px;
            padding: 0 10px;

            font-size: 14px;
            font-weight: $font-light;
            line-height: 38px;
            text-transform: uppercase;
            text-align: center;
            color: #CCCCCC;

            border: 1px solid #CCCCCC;
            border-bottom-color: white;
            
            @include mappy-bp(medium) {
                min-width: 216px;
                height: 50px;

                font-size: 16px;
                letter-spacing: 2px;
                line-height: 52px;
            }
        }

        a.active {
            color: $color-1;
            background: white;

            &:before {
                content: '';
                position: absolute;
                top: 101%;
                left: 0;
                z-index: 2;

                width: 100%;
                height: 3px;

                background: white;
            }
        }

        a:not(:first-of-type) {
            margin-left: -1px;
        }
    }
    /********************************************/

    .contact-container {

        .col-left,
        .col-right {
            width: 100%;
            @include mappy-bp(medium) {
                width: 50%;
                max-width: 570px;
            }
        }

        .col-left {
            h3 {
                margin-bottom: 0;

                font-size: 17px;
                font-weight: $font-bold;
                line-height: 30px;
                color: $color-2;
            }

            .numbers {
                p, a {
                    display: inline-block;
                    margin-bottom: 0;

                    padding: 0 5px;
                    font-size: 22px;
                    font-weight: $font-bold;
                    line-height: 35px;
                    letter-spacing: 0.91px;
                    text-transform: uppercase;
                    color: $color-2;

                    @include mappy-bp(mediumish) {
                        margin-bottom: 27px;
                        padding: 0 25px 0 0;
                    }

                    span {
                        display: inline-block;
                        padding-right: 4px;

                        letter-spacing: 0.96px;
                        color: $color-1;
                    }
                }
            }

            p {
                margin-bottom: 20px;
                font-weight: $font-light;

                @include mappy-bp(mediumish) {
                    margin-bottom: 34px;
                }
            }

            .email {
                display: block;
                margin: 20px 0;

                font-size: 22px;
                line-height: 26px;
                color: $color-1;

                @include mappy-bp(mediumish) {
                    margin: 0 0 43px;
                }
            }

        }

        .col-right {
        }

        .map-area {
            width: 100%;
            margin: 72px 0 0;

            .map {
                width: 100%;
                height: 340px;
                margin: auto;
            }
        }
    }
    /********************************************/
}