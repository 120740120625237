/*********************************************
:: Divisions
********************************************/
.page-divisions {

    .body-container {
        width: 100%;
        padding: 0 0 45px;

        @include mappy-bp(mediumish){
            padding: 0 0 80px;
        }

        .content-narrow-width {
            max-width: 570px;
            margin: auto;
            padding: 30px 3%;

            text-align: center;

            @include mappy-bp(mediumish){
                padding: 65px 0 52px;
            }
        }

        .content-max-width {
            width: 970px;
        }

        article {
            position: relative;
            width: 100%;
            max-width: 470px;
            height: 285px;
            margin-bottom: 30px;
 
            @include mappy-bp(medium){
                width: calc(50% - 15px);
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                background: black;

                opacity: 0;
                pointer-events: none;
                transition: all 0.3s ease-in-out;
            }

            a {
                display: inline-block;
                width: 100%;
                height: 100%;

                background-size: cover;
            }

            h3 {
                position: absolute;
                bottom: 0;
                right: 0;
                min-width: 300px;
                min-height: 80px;
                margin: 0;
                padding: 28px 30px 26px;
                
                font-size: 22px;
                font-weight: $font-bold;
                line-height: 26px;
                color: $color-2;

                background: white;
                z-index: 2;
            }
        }

        article:hover {
            &:before {
                opacity: 0.3;
            }
        }      
    }
    /********************************************/

}
