/*********************************************
:: Upsell Module                    
********************************************/
.upsell-module {
    padding: 30px 0 50px;

	@include mappy-bp(mediumish){
        padding: 45px 0 80px;
	}

    .title {
        position: relative;

        text-align: center;

        @include mappy-bp(mediumish){
            margin-bottom: 30px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 11px;
            left: 0;
            width: 100%;
            height: 1px;

            background: $color-1;
        }

        h3 {
            position: relative;
            width: 300px;
            margin: 0 auto;

            font-size: 18px;
            font-weight: 400;
            letter-spacing: 3px;
            line-height: 22px;
            text-transform: uppercase;
            color: #292929;

            background: white;
            z-index: 2;
        }
    }

    .products-slideshow {
        // max-width: 970px;
        margin: 0 auto;
    }

    .slick-arrow {
        &:before {
            font-family: $font-icon;
            font-size: 51px;
            color: #CCCCCC !important;
        }

        &.slick-prev,
        &.slick-next {
            width: 50px;
            height: 50px;
        }

        &.slick-prev {
            left: -10px;
            @include mappy-bp(mediumish){
                left: -40px;
            }

            &:before {
                content: "\e927";
            }
        }

        &.slick-next {
            right: -10px;
            @include mappy-bp(mediumish){
                right: -40px;
            }

            &:before {
                content: "\e928";
            }
        }
    }
}
/********************************************/