/*********************************************
:: Buttons                             
********************************************/

button {
    outline: none;
}

/*******************************************/
// Default Button
button.button,
input.button,
a.button {
    display: inline-block;
    height: 40px;
    padding: 0px 12px;

    font-family: $font-family-2;
    font-size: 13px;
    font-weight: $font-semibold;
    line-height: 38px;
    letter-spacing: 2px;
    text-decoration: none;
    text-align: center;
    color: $color-1;

    background: transparent;
    border: 1px solid $color-1;
    transition: all .4s ease-in-out;

    &:hover {
		background: $color-1;
        color: white;
    }

    &.small {
        height: 32px;
        line-height: 30px;
    }

    &.large {
        height: 50px;
        line-height: 48px;
    }

    img {
        margin-left: 5px;
    }
}

/*******************************************/
// Button Colors 
.button.white {
    color: black;
    background: white;
    border-color: white;

    @include mappy-bp(medium){
        &:hover, &:focus, &:active{
			background: black;
            color:white;
        }
    }
}

.button.black {
    color: white;
    background: black;
    border-color: black;

    @include mappy-bp(medium){
        &:hover, &:focus, &:active{
			background: white;
            color:black;
        }
    }
}

.button.blue {
    letter-spacing: 2px;
    color: white;
    
    background: $color-1;
    border-color: $color-1;

    @include mappy-bp(medium){
        &:hover, &:focus, &:active{
			background: black;
            border-color: black;
            color:white;
        }
    }
}

/*******************************************/
// Button Back (on product-detail page)
.button-back {
    display: inline-block;
    width: 100px;
    height: 32px;
    margin: 0;
    padding: 0 7px 0 7px;

    font-size: 13px;
    font-weight: $font-light;
    line-height: 30px;
    text-align: left;
    color: #8b8b8b;

    border-radius: 0;
    border: 1px solid #CCCCCC;

    span {
        display: inline-block;
        width: 25px;
        height: 30px;
        margin-right: 11px;

        font-weight: $font-semibold;
        line-height: 30px;
        text-align: left;
        color: $color-1;
    }

    &:hover {
        border-color: $color-1;
    }
}

/*******************************************/
// Disabled Button Styles
.button-disabled,
.button[disabled],
button[disabled],
button[type="submit"][disabled],
input[type="submit"][disabled],
input[type="button"][disabled],
input[type="reset"][disabled]{
	color:white;
	opacity: 0.5;
	cursor: default;
}

