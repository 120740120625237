/*********************************************
:: Resources
********************************************/
.page-resources {

    .search-container {
        width: 100%;
        padding-top: 20px;

        @include mappy-bp(mediumish){
            padding: 40px 0 110px;
        }

        aside.filters {
            position: fixed;
            top: 0;
            left: -85%;

            float: left;
            width: 85%;
            height: 100%;
            padding: 20px;

            background-color: #f3f7f9;
            transition: all 0.4s cubic-bezier(0.63, 0.01, 0, 1.03);
            z-index: 9999;

            @include mappy-bp(mediumish){
                position: static;
                width: 300px;
                padding: 1px 0 0 0;

                background: white;
            }

            &.open-menu {
                left: 0%;

                box-shadow: 0 0 12px 12px rgba(0, 0, 0, 0.2);
                overflow: scroll;
            }

            .resource-filter {
                h4 {
                    margin-bottom: 15px;
                }

                ul {
                    margin: 0;

                    li > a:before {
                        top: 4px;
                    }
                    a {
                        cursor: pointer;
                    }
                }
            }
        }

        .resources {
            margin: auto;
            width: 100%;

            @include mappy-bp(mediumish){
                width: calc(100% - 300px);
                float: right;
            }

            .products-wrapper {
                @include mappy-bp(mediumish){
                    padding-left: 126px;
                }
            }

            article {
                position: relative;
                display: none;
                width: 100%;
                margin: 0 0 2px;
                padding: 20px;

                font-size: 0;
                background: #f3f7f9;

                transition: all 0.3s ease-in-out;

                img, h4, p, button {
                    display: inline-block;
                    vertical-align: middle;
                }

                img {
                    width: 30px;
                    height: 40px;
                    margin: 0 16px 0 0;
                    transform: scale(1);
                    transition: 0.5s ease all;
                }

                h4 {
                    width: calc(100% - 50px);
                    margin: 0;

                    font-size: 15px;
                    font-weight: $font-medium;
                    line-height: 15px;
                    color: $color-2;

                    transition: all 0.5s ease-in-out;

                    @include mappy-bp(mediumish){
                        width: 546px;
                    }
                }

                p {
                    margin: 10px 5px 10px 0;
                    font-size: 13px;
                    font-weight: $font-light;
                    line-height: 15px;

                    @include mappy-bp(mediumish){
                        width: 108px;
                        margin: 0;
                    }
                }

                button {
                    font-size: 12px;
                    font-weight: $font-bold;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    @include mappy-bp(mediumish){
                        width: 115px;
                    }

                    img {
                        display: inline-block;
                        width: 16px;
                        height: 14px;
                        margin: 0 0 0 10px;
                    }
                }
            }

            article:hover {
                background: darken(#f3f7f9, 10%);

                button {
                    color: $color-2;
                }
            }

            .wrap {
                padding: 40px 0;
                @include mappy-bp(mediumish){
                    padding: 0;
                }
            }

            .videos {
                width: 100%;
                margin: 0;
                text-align: center;

                @include mappy-bp(mediumish){
                    margin: 0;
                }
            }

            .video {
                display: none;
                width: 100%;
                margin: 0 auto;

                @include mappy-bp(medium){
                    width: calc(50% - 35px);
                    margin: 0;
                }

                iframe {
                    display: inline-block;
                    width: 100%;
                    max-width: 400px;
                    height: 210px;
                    margin-bottom: 12px;
                }

                h3 {
                    margin-bottom: 47px;

                    font-size: 15px;
                    font-weight: 500;
                    line-height: 15px;
                    color: $color-2;

                    @include mappy-bp(medium){ text-align: left; }
                }
            }

            .active {
                display: block;
            }
        }

        .filter-close {
            position: absolute;
            top: 18px;
            right: 30px;

            span {
                display: inline-block;
                font-size: 14px;
                font-weight: $font-bold;
                color: $color-2;
            }
        }

        .filter-trigger {
            float: right;
            margin: 0 0 30px;

            font-size: 15px;
            font-weight: $font-semibold;
            letter-spacing: 0.7px;
            text-transform: uppercase;

            @include mappy-bp(mediumish){
                margin: 5px 0 0 0;
            }
        }
    }
    /********************************************/

}
