/*********************************************
  :: Product Detail
********************************************/
.page-product-detail {

	.detail-container {
        display: inline-block;
		width: 100%;
		padding-top: 40px;

		.images {
			float: left;
            width: 100%;
            margin: 0 0 20px;

            text-align: left;

			@include mappy-bp(mediumish){
				width: 50%;
                margin: 0;
			}

			.product-image {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                max-width: 500px;
                margin: 0 auto 10px;

				@include mappy-bp(mediumish){
                    width: 370px;
                    height: 370px;
                    margin: 35px 0 20px 0;
				}
                @include mappy-bp(largeish){ margin: 35px 0 20px 100px; }

				#large-img {
                    display: block;
					max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }
            }

			.slick-container {
				position: relative;
                margin: 20px auto 0;

                width: 100%;
                max-width: 370px;


				@include mappy-bp(mediumish){
                    float: left;
					margin: 0 0 32px 100px;
				}

				.product-slideshow {
                    margin: auto;
                    max-width: 370px;

                    .thumb {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        height: 110px;
                        margin: 0 10px;

                        cursor: pointer;

                        img {
                            display: block;
                            width: 70%;
                            max-width: 110px;
                            margin: 0 auto;

                            object-fit: cover;
                            opacity: 0.5;
                        }

                        img.border { opacity: 1; }
                    }
                }

                .slick-arrow {
                    &:before {
                        font-family: $font-icon;
                        font-size: 20px;
                        color: #CCCCCC !important;
                    }

                    &.slick-prev {
                        left: -10px;
                        @include mappy-bp(mediumish){
                            left: -40px;
                        }

                        &:before {
                            content: "\e927";
                        }
                    }

                    &.slick-next {
                        right: -10px;
                        @include mappy-bp(mediumish){
                            right: -40px;
                        }

                        &:before {
                            content: "\e928";
                        }
                    }
                }
			}
		}

		.content {
			float: left;
			width: 100%;
			margin: 0 0 30px 0;

			@include mappy-bp(mediumish){
                float: right;
                width: 50%;
				margin: 0;
                padding: 68px 0 0 0;
			}
            @include mappy-bp(largeish){ padding: 68px 100px 0 14px; }

			h1 {
				margin-bottom: 24px;

                font-size: 30px;
                font-weight: 300;
                line-height: 35px;
                color: #1f1f1f;
			}

            a.button {
                min-width: 170px;
                margin-bottom: 50px;

                line-height: 40px;
            }

			.skus {
                margin-bottom: 46px;

                p {
                    margin-bottom: 20px;
                    
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 20px;
                    color: $color-2;

                    span {
                        display: inline-block;
                        min-width: 128px;

                        text-transform: uppercase;
                        color: $color-1;
                    }
                }
            }

            .tab-links {
                position: relative;
                display: inline-block;
                width: 100%;
                margin: 0 auto 22px;

                font-size: 0;
                
                &:after {
                    content: '';
                    position: absolute;
                    top: calc(100% - 1px);
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: #8b8b8b;
                }

                a {
                    position: relative;
                    display: inline-block;
                    width: auto;
                    margin: 0 14px 0 0;
                    padding: 0 0 8px;

                    font-size: 12px;
                    font-weight: 300;
                    line-height: 15px;
                    text-transform: uppercase;
                    color: #848484;

                    border-bottom: 4px solid transparent;
                    z-index: 2;

                    @include mappy-bp(largeish){
                        margin: 0 33px 0 0;

                        font-size: 14px;
                        letter-spacing: 1px;
                    }
                }

                a:last-of-type {
                    margin: 0;
                }

                a.active {
                    border-color: $color-1;
                }
            }

            .tab-content {
                width: 100%;
                display: none;

                &.active {
                    display: block;
                }

                ul, ol {
                    margin: 0 0 25px;
                    
                    li {
                        position: relative;
                        margin-bottom: 10px;
                        padding-left: 20px;

                        font-size: 14px;
                        font-weight: 300;
                        line-height: 23px;
                        color: black;

                        &:before {
                            content: "\2022";
                            position: absolute;
                            top: -2px;
                            left: 0;

                            font-size: 26px;
                        }
                    }
                }

                p {
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 23px;
                    color: black;
                }

                .download-tab {
                    font-size: 0;
                    vertical-align: top;

                    a {
                        display: inline-block;
                        width: 100%;
                        margin-bottom: 10px;

                        font-size: 0;

                        @include mappy-bp(mediumish){
                            width: 50%;
                        }

                        img {
                            display: inline-block;
                            vertical-align: top;
                            width: 25px;
                            margin: 0 20px 15px 0;
                        }
                        .right {
                            display: inline-block;
                            width: calc(100% - 45px);

                            p {
                                margin: 0;
                                line-height: 16px;
                            }
                            span {
                                font-size: 10px;
                            }
                        }
                    }
                }

                .video-tab {
                    ul li {
                        &:before {
                            content: "";
                        }
                    }

                    iframe {
                        width: 100%;
                        min-height: 150px;
                        @include mappy-bp(medium) {
                            min-height: 200px;
                        }
                        @include mappy-bp(mediumish) {
                            min-height: 280px;
                        }
                    }
                }
            }
        }
	}
	/********************************************/

}
/********************************************/
