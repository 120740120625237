/*********************************************
:: About Page                   
********************************************/
.page-about {

    .detail-container {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            background: url(/images/about.svg) no-repeat top left;
            background-size: cover;

            opacity: 0.1;

            @include mappy-bp(mediumish){
                background-size: contain;
                width: 50.5%;
            }
            @include mappy-bp(huge){
                opacity: 1;
            }
        }

        .right-content {
            position: relative;
            width: 100%;
            padding: 60px 3%;

            @include mappy-bp(mediumish){
                float: right;
                width: 100%;
                max-width: 1005px;
                padding: 180px 0 100px;

                font-size: 0;
            }

            .text {
                @include mappy-bp(mediumish){
                    display: inline-block;
                    vertical-align: top;
                    width: 570px;
                    margin: 0 100px 20px 0;
                    padding-left: 3%;
                }
                @include mappy-bp(largeish){
                    padding-left: 0;
                }

                h2.leadcopy {
                    margin-bottom: 33px;
                }

                .wysiwyg {
                    p {
                        color: black;
                    }
                }
            }

            #hero {
                display: block;
                width: 335px;
                margin: 60px auto;

                @include mappy-bp(mediumish){
                    display: inline-block;
                    margin: 0;
                }
            }
        }
    }
    /********************************************/
    
}
