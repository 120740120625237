/*********************************************
:: Mega Menu Module                    
********************************************/
.mega-menu-module {
    position: absolute;
    top: 80px;
    left: 0;
	width: 100%;
	padding: 30px 0;

    background-color: #f3f7f9;

    opacity: 0;
    z-index: -9999;

	@include mappy-bp(medium){
        top: 80px;
		padding: 82px 0;
	}

    @include mappy-bp(mediumish){
        top: 127px;
    }

    .content-max-width {

        &:before {
            content: '';
            position: absolute;
            top: -40px;
            left: 65%;
            width: 0;
            height: 0;

            border-bottom: 10px solid #f3f7f9;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;

            @include mappy-bp(medium){
                top: -90px;
                left: calc(100% - 475px);
            }
        }
    }

    .tabs {
        margin-bottom: 50px;
        border-bottom: 2px solid $color-2;

        a {
            position: relative;
            display: inline-block;
            width: auto;
            margin: 0 20px 0 0;
            padding: 0 0 19px;

            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            text-transform: uppercase;
            color: $color-2;
            transition: all 0s ease-in-out;

            @include mappy-bp(medium){
                margin: 0 39px 0 0;

                font-size: 22px;
                line-height: 26px;
                letter-spacing: 1px;
            }
        }

        a.active {
            font-weight: 500;

            &:after {
                content: '';
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 9px;
                background: $color-1;
            }
        }

        a:last-of-type {
            margin-right: 0;
        }
    }

    .tab-content {
        display: none;
        columns: 2 100px;
        column-gap: 20px;

        @include mappy-bp(medium){
            columns: 4 170px;
        }
        @include mappy-bp(mediumish){
            columns: 5 170px;
            column-gap: 30px;
            height: 300px;
        }

        &.unlimited-height {
            height: auto;
        }

        .category {
            display: inline-block;
            width: 100%;

            h4 {
                margin-bottom: 13px;
                line-height: 21px;

                a {
                    font-size: 16px;
                    font-weight: 700;
                    letter-spacing: 1px;
                    line-height: inherit;
                    text-transform: uppercase;
                }
            }

            ul {
                margin: 0 0 25px;

                li {
                    margin-bottom: 8px;
                    font-size: 16px;
                    line-height: 20px;

                    a {
                        color: #303030;
                    }
                }
            }
        }
    }

    .tab-content.active {
        display: block;
    }
}

.mega-menu-module.active {
    opacity: 1;
    z-index: 99999;
}
/********************************************/