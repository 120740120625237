/*********************************************
  :: Site Header
********************************************/
.site-header {
	position: relative;
	width: 100%;
	height: $header-height-mobile;
	z-index: 99;

	@include mappy-bp(mediumish){
		height: $header-height-desktop;
	}

	.logo {
		position: absolute;
		top: 28px;
		left: 3%;
		height: 24px;
		width: auto;

		transition: none;

		@include mappy-bp(small){
			top: 20px;
			height: 40px;
		}

		@include mappy-bp(mediumish){
			top: 40px;
			left: 0;
			width: 280px;
			height: auto;
		}

		img {
			width: 100%;
			height: 100%;

			object-fit: contain;

			//height: $header-height-mobile - 10;
			@include mappy-bp(mediumish){
				height: 55px;	
			}
		}
	}

	nav {
		position: absolute;
		top: 23px;
		right: 0;
		width: 587px;

		font-size: 0;
		text-align: right;

		@include mappy-bp(max-width mediumish){
			display: none;
		}

		ul {
			width: 100%;
			margin-bottom: 20px;
		}

		li { 
			position: relative;
			display: inline-block;
			margin: 0 0 0 15.5px;
		}

		a {
			display: inline-block;
			font-size: 15px;
			line-height: 18px;
			color: $color-2;
			
			&:hover {
				opacity: 0.7;
			}

			&.selected {
				color: $color-1; 
				&:hover { opacity: 1; }
				&:active { opacity: 1; }
			}
		}

		.search {
			width: 100%;

			button {
				display: inline-block;
				vertical-align: top;
				width: 147px;
				height: 40px;

				font-size: 22px;
				font-weight: 500;
				color: $color-2;

				border-color: transparent;

				img, span {
					position: relative;
					top: -4px;
					display: inline-block;
					vertical-align: middle;
				}

				img {
					margin: 6px 0 0 5px;
				}
			}

			form {
				display: inline-block;
				width: calc(100% - 147px);

				input {
					float: left;
					width: calc(100% - 130px);
					border-radius: 0;
				}

				.selectric {
					width: 130px !important;
				}

				.selectric-items {
					min-width: 180px;
					right: 0;
					left: auto;
				}
			}
		}
	}

	.tel-mob {
		display: inline-block;
		position: absolute;
		top: 10px;
		right: 3%;

		font-size: 12px;
		line-height: 14px;
		letter-spacing: 2px;
		color: $color-1;

		@include mappy-bp(mediumish){
			display: none;
		}
	}

	.button {
		letter-spacing: 0;

		&:hover {
			background: white;
			color: $color-2;
		}
	}

	.button.mobile {
		position: absolute;
		top: 35px;
		right: calc(3% + 60px);
		height: 30px;
    	line-height: 28px;
	}

	.hamburger {
        position: absolute;
        top: 40px; 
		right: 3%;

		width: 26px;
    	height: 17px;

		span {
			font-size: 17px;
			color: black;
		}

        &:focus {
            outline: none;
        }
    }
}

/********************************************/