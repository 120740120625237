/*********************************************
  :: Main Mob Menu                             
********************************************/
body.open-menu {
    #mobile-menu {
        right: 0;

        .menu-close-icon {
           z-index: 999;
        }
    }
}

#mobile-menu {
    position: fixed;
    top: 0;
    bottom: 0; 
    right: -323px;
    z-index: 999;

    width: calc(100% - 52px);
    max-width: 323px;

    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    
    transition: all .25s;
    overflow: auto;
    -webkit-backface-visibility: hidden;

    @include mappy-bp(mediumish){
        display:none;
    }

    .menu-close-icon {
        position: absolute;
        top: 0; right: 0;

        width: 52px;
        height: 56px;

        border: none;
        background-color: $color-1; 
        outline: none;

        span {
            font-size: 22px;
            line-height: 26px;
            text-align: center;
            color: white;
        }
    }

    .menu-content {
        overflow-y: auto;
        &::-webkit-scrollbar { 
            display: none; 
        }
    }

    a {
        font-family: $font-family-1;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        color: black;
        line-height: 24px;
        text-transform: uppercase;
    }

    ul {
        padding: 30px 15px 0 20px;
    }

    li {
        margin: 0;
        padding: 13px 0;
        border-bottom: 1px solid #eee;

        ul {
            display: none;
            padding: 15px 0 0 15px;
        }

        li:last-child {
            border-bottom: none;
        }
    }

    li.mobile-menu-parent {
        a.title {
            color: $color-1;
        }

        ul {
            li {
                a  {
                    text-transform: none;
                }
            }
        }
    }

    .icon {
        float: right;
        font-weight: 700;
    }
}