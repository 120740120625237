/*********************************************
:: Cat Filter Module
********************************************/
.category-filter-module {

    @include mappy-bp(mediumish){
        max-width: 173px;
    }

    h4 {
        margin-bottom: 13px;

        font-size: 18px;
        font-weight: 500;
        letter-spacing: 3px;
        line-height: 18px;
        text-transform: uppercase;
        color: $color-1;
    }

    ul {
        margin: 0 0 25px;
    }

    li {

        .title {
            position: relative;
            display: inline-block;

            a {
                margin: 0 0 0 12px;

                font-size: 14px;
                font-weight: $font-medium;
                line-height: 20px;
                color: $color-2;
            }

            span {
                position: absolute;
                top: 2px;
                left: 0px;

                font-family: $font-icon;
                font-size: 7px;
                font-weight: $font-light;
                cursor: pointer;

                &:before {
                    content: "\e91a";
                }
            }
        }
        &.active {
            .title {
                span {
                    position: absolute;
                    top: 2px;
                    left: 0px;
                    font-family: $font-icon;
                    font-size: 8px;
                    color: $color-1;

                    &:before {
                        content: "\e91b";
                    }
                }
            }
        }
    }

    .parent {
        margin: 0 0 6px 0;

        &.active a {
            color: $color-1;
        }
    }

    .sub-menu {
        display: none;
        margin-bottom: 0;

        .sub-parent {
            margin: 5px 0;

            a {
                display: inline-block;
                margin: 0 0 0 12px;

                font-size: 14px;
                font-weight: $font-light;
                line-height: 20px;
                color: $color-2;
            }

            a.active {
                font-weight: $font-medium;
            }
        }
    }

    form {
        label {
            position: relative;
            display: inline-block;
            width: 100%;
            margin: 0 0 10px;

            .checkbox {
                position: absolute;
                top: 2px;
                left: 0;

                display: block;
                width: 17px;
                height: 17px;

                font-family: "icomoon";
                font-size: 9px;
                text-align: center;
                line-height: 11px;
                color: white;

                border: 2px solid #d7d7d7;
                border-radius: 50%;

                &:before {
                    content: '\e91c';
                }
            }

            input {
                position: relative;
                top: 2px;
                width: 17px;
                height: 17px;
                border-radius: 50%;

                opacity: 0;
                z-index: 9;
            }

            input[type="checkbox"]:checked + .checkbox {
                background: $color-1;
                border-color: $color-1;

                &:before {
                    position: relative;
                    top: 1px;
                }
            }


            span {
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
            }
        }
    }
}
/********************************************/

