/*********************************************
:: Forms
********************************************/

form {
}

legend {
    margin-bottom: 15px;
    @extend h2;
}

label {
    display: inline-block;
    margin-bottom: 5px;

    font-size: 13px;
    line-height: 17px;
    font-weight: $font-normal;
    color: $color-2;
}

/*******************************************/
// Disabled Field Styles
.field-disabled,
input[disabled],
textarea[disabled],
select[disabled],
input[readonly],
textarea[readonly],
select[readonly] {
    opacity: 0.5;
    cursor: default;
}

/*******************************************/
// Core Field Styles
input, textarea, select {
    display: inline-block;
    width: 100%;
    height: 40px;
    padding: 0px 15px;

    font-size: 14px;
    line-height: 38px;
    
    background: white;
    border: 1px solid #CCCCCC;
    border-radius: 3px;
    outline: none;
    transition: all .5s ease-in-out;

    &:hover, &:focus {
        background: #f1f1f1;
        border: 1px solid #CCC;
        transition: all .5s ease-in;
    }

}

/*******************************************/
// Misc Field Styles
*::placeholder {
    font-family: $font-family-1;
    font-size: 14px;
    font-weight: 300;
    color: #8b8b8b;
}

textarea, select[multiple] {
    height: 160px;
}

input[type=checkbox], input[type=radio] {
    height: auto;
}

/*******************************************/
// Custom Checkbox/Radio Styles (no JS)
.form-field .custom-checkbox-radio {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0 0 5px;

    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    cursor: pointer;

    input {
      display: inline-block;
      width: 15px;
      height: 15px;
      margin: 2px 5px 0 0;
      padding: 0;

      background: none;
      border: none;
      cursor: pointer;
    }

}

/*******************************************/
// Form Field Container
.form-field {
    float:left;
    width: 100%;
    padding: 0 0 13px;
}

/*******************************************/
// Button Field Container
.button-field {
    float:left;
    width: 100%;

    text-align: center;

    button,
    input[type="submit"],
    input[type="reset"] {
        min-width: 112px;
        text-transform: uppercase;

        @include mappy-bp(mediumish){
            float:right;
        }
    }
}

/*******************************************/
// Form Fields Stack

.form-fields-stack {
    lost-utility: clearfix;

    &.two-column {
        .form-field {
            width: 100%;
            @include mappy-bp(mediumish){
                lost-column: 1/2 2 20px;
            }
        }
    }

    &.three-column {
        .form-field {
            width: 100%;
            @include mappy-bp(mediumish){
                lost-column: 1/3 3 20px;
            }
        }
    }
}

/*******************************************/
// Form Feedback
.form-field.error input,
.form-field.error textarea,
.form-field.error select,
.form-field.error .SumoSelect>.CaptionCont {
    border-color: $color-attention;
}

.form-field.valid input,
.form-field.valid textarea,
.form-field.valid select,
.form-field.valid .SumoSelect>.CaptionCont{
    color: black;
    border-color: #CCCCCC;
}

label.error, label.error-SumoSelect-label, .php-error li {
    margin: 10px 0 0;
    font-weight: $font-bold;
    color: $color-attention !important;
}

.form-note {
    display: inline-block;
    margin-bottom: 20px;
    font-weight: $font-normal;
    line-height: 1;
}
