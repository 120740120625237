/*********************************************
:: Home Page                   
********************************************/
.page-home {

    .hero-container {
        position: relative;
    	width: 100%;

        .slide {
            position: relative;
            width: 100%;

            .bg {
                position: relative;
                width: 100%;
                height: 100%;
                @include bg(cover);
                min-height: 200px;

                @include mappy-bp(medium){ 
                    min-height: 450px;
                }
                @include mappy-bp(large){
                    width: 100%;
                    min-height: 650px;
                    // width: calc(100% - ((100% - 1170px)/2));
                    // margin-right: calc((100% - 1170px)/2);
                }
            }
        }

    	.slide-text {
    		position: absolute;
            top: 100px;
            right: 3%;
            left: 3%;

    		@include mappy-bp(medium){
    			top: auto;
                bottom: 90px;
                right: 95px;
                left: auto;
                max-width: 570px;
    		}

    		h1, h2 {
                margin: 0;
    			font-size: 22px;
    			font-weight: 900;
                line-height: 28px;
    			color: white;

    			@include mappy-bp(medium){
    				font-size: 60px;
                    line-height: 70px;
    			}
    		}
    	}
    }
    /********************************************/
    .blurb-container {
        padding: 55px 3%;
        text-align: center;

        @include mappy-bp(mediumish){
            padding: 95px 0 68px;
        }

        h2 {
            margin-bottom: 13px;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 3px;
            line-height: 22px;
            text-transform: uppercase;
            color: $color-1;
        }
        p {
            font-size: 18px;
            font-weight: 300;
            line-height: 24px;

            @include mappy-bp(mediumish){
                font-size: 25px;
                line-height: 35px;
            }
        }
    }
    /********************************************/
    .products-container {
        padding: 0 0 55px;
        text-align: center;

        @include mappy-bp(mediumish){
            padding: 0 0 110px;
        }

        .content-max-width {
            max-width: 1200px;
            padding: 0 5px;

            &.flex {
                justify-content: center;
            }
        }

        article {
            width: 100%;
            height: 330px;
            margin: 0 0 20px;

            @include bg(cover);

            @include mappy-bp(medium){
                width: calc(33.33% - 30px);
                margin: 0 15px 30px;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
            }

            p {
                width: calc(100% - 42px);
                margin: 0;
                padding: 10px 0;

                font-size: 18px;
                font-weight: 900;
                letter-spacing: 2px;
                line-height: 20px;
                text-transform: uppercase;
                color: white;

                border: 4px solid #ffffff;
                background: transparent;
                transition: background 0.5s ease-in-out;

                @include mappy-bp(mediumish){
                    width: calc(100% - 102px);
                    padding: 35px 0;

                    font-size: 25px;
                    line-height: 35px;
                    border: 4px solid #ffffff;
                }
            }
        }

        article a:hover {
            p {
                background: rgba(black, 0.3);
            }
        }
    }
    /********************************************/
    .app-container {
        position: relative;
        padding: 55px 0 0;
        font-size: 0;

        @include mappy-bp(medium){
            padding: 0;
        }

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;

            background: #f3f7f9;

            @include mappy-bp(mediumish){
                height: 87%;
            }
        }

        .left,
        .right {
            width: 100%;

            @include mappy-bp(medium){
                width: calc(50% - 15px);
            }
        }

        .left {
            padding: 0 0 55px 0;
            @include mappy-bp(mediumish){
                padding: 146px 100px 0 0;
            }

            h3 {
                margin-bottom: 14px;
                font-size: 35px;
                font-weight: 900;
                line-height: 41px;
            }

            p {
                margin-bottom: 39px;
                font-size: 19px;
                font-weight: 300;
                line-height: 29px;
            }

            .app {
                a {
                    display: inline-block;
                    margin-right: 25px;

                    img {
                        height: 50px;
                    }
                }
            }
        }

        .right {
            @include mappy-bp(medium mediumish){
                position: relative;

                img {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
            }
        }
    }
    /********************************************/
    .logo-container {
        padding: 73px 0;

        .content-max-width {
            @include mappy-bp(largeish){
                max-width: 1270px;
            }
        }

        a {
            display: flex;
            justify-content: space-between;
            flex-flow: wrap;
            width: 50%;
            min-height: 85px;

            @include mappy-bp(medium){
                width: 33.33%;
            }
            @include mappy-bp(mediumish){
                width: calc(20% - 20px);
            }

            img {
                display: block;
                align-self: center;
                width: 100%;
                max-width: 50%;
                margin: 0 auto;

                transform: scale(1);
                transition: 0.3s ease all;
            }
        }

        a:hover {
            img {
                transform: scale(1.08);
            }
        }
    }
    /********************************************/
    
}
