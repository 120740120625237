/*********************************************
:: Branches Page
********************************************/
.page-branches {

    .body-container {
        width: 100%;
        padding: 0 0 45px;

        text-align: center;

        .content-narrow-width {
            max-width: 570px;
            margin: auto;
            padding: 30px 3%;

            @include mappy-bp(mediumish){
                padding: 65px 0 52px;
            }
        }

        article {
            width: 100%;
            margin-bottom: 20px;
            padding: 20px 10px;

            text-align: center;
            background: #f3f7f9;

            @include mappy-bp(smallish){
                width: calc(50% - 20px);
                padding: 30px;
            }
            @include mappy-bp(mediumish){
                width: calc(33.33% - 30px);
                margin-bottom: 30px;
                padding: 55px 30px 59px;
            }

            h3 {
                position: relative;
                margin-bottom: 53px;
                padding-bottom: 16px;

                font-size: 20px;
                font-weight: $font-bold;
                line-height: 20px;
                text-transform: uppercase;
                letter-spacing: 3px;
                color: $color-2;

                &:after {
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: 0; right: 0;
                    margin: auto;
                    width: 50px;
                    height: 1px;
                    background-color: $color-1;
                }
            }

            p {
                margin-bottom: 21px;
                font-size: 16px;
                color: black;
            }

            .numbers {
                p, a {
                    display: inline-block;
                    margin-bottom: 19px;
                    padding: 0 5px;

                    font-size: 17px;
                    font-weight: 500;
                    line-height: 21px;
                    text-transform: uppercase;
                    color: $color-2;

                    span {
                        color: $color-1;
                    }
                }
            }

            .email {
                display: inline-block;
                margin-bottom: 35px;

                font-size: 17px;
                font-weight: $font-bold;
                line-height: 18px;
            }

            .button {
                width: 130px;

                line-height: 40px;
                text-transform: uppercase;
            }
        }
    }
    /********************************************/

}

.map-display,
.map-bg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
}

.map-display {
    display: none;
    z-index: 9999;

    background-color: rgba(#303030, 0.56);

    &.active {
        display: block;
    }

    .map-exit {
        position: absolute;
        left: calc(100% - 26px);
        top: calc(100% - 26px);
        width: 57px;
        height: 57px;

        cursor: pointer;
        z-index: 9;

        @include mappy-bp(medium) {
            top: calc(50% - 255px);
        }
        @include mappy-bp(largeish) {
            left: calc(50% + 555px);
        }

        img {
            float: right;
            width: 100%;
            max-width: none;
            height: 100%;
        }
    }

    #map {
        position: absolute;
        top: 10%;
        left: 10%;
        z-index: 3;
        width: 80%;
        height: 80%;

        @include mappy-bp(medium) {
            height: 456px;
            top: calc(50% - 228px);
        }
        @include mappy-bp(largeish) {
            width: 1170px;
            height: 560px;
            left: calc(50% - 585px);
        }
    }
}
