/*********************************************
  :: Site Footer
********************************************/
.site-footer {
    position: relative;
    width: 100%;
    min-height: $footer-height-mobile;
    background: $color-2;
    border-bottom: 4px solid white;
    
    @include mappy-bp(mediumish){
        min-height: $footer-height-desktop;
    }

    .footer-links {
        padding: 46px 0 20px;

        @include mappy-bp(mediumish){
            padding-bottom: 108px;
        }

        .content-max-width {
            font-size: 0;
        }

        .left,
        .right {
            display: inline-block;
            vertical-align: top;
            width: 100%;

            text-align: center;
        }
        .left {
            margin-bottom: 20px;

            @include mappy-bp(mediumish){
                width: 500px;
                margin-bottom: 0;

                text-align: left;
            }
            
            img {
                max-width: 180px;
                @include mappy-bp(mediumish) {
                    max-width: 230px;
                }
            }
        }
        .right {
            @include mappy-bp(mediumish){
                width: calc(100% - 500px);
                text-align: left;
            }

            .col, .social {
                display: inline-block;
                vertical-align: top;
                width: 200px;

                @include mappy-bp(max-width mediumish){
                    margin: 0;
                }
                
            }
            .social {
                margin-top: 20px;

                @include mappy-bp(mediumish){
                    float: right;
                    margin-top: 0;

                    text-align: right;
                }

                a {
                    margin: 0 6px;
                    @include mappy-bp(mediumish){
                        margin: 0 0 0 17px;
                    }
                }
            }

            li {
                margin-bottom: 6px;

                a {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 14px;
                    color: white;
                }
            }
        }
    }

    .footer-legal {
        padding-bottom: 20px;
        
        p, a {
            display: inline-block;
            width: 100%;

            font-size: 11px;
            line-height: 11px;
            text-align: center;
            color: #9b9b9b;

            @include mappy-bp(medium){
                width: auto;
            }
        }
 
        p.copyright {
            margin-bottom: 5px;

            @include mappy-bp(medium){
                float:left;
                margin-bottom: 0px;
            }

            a {
                font-size: 11px;
                line-height: 11px;
                color: $color-4;
            }
        }

        a.attribution {
            @include mappy-bp(medium){
                float:right;
            }
        }
    }
}
