/*********************************************
:: Layout
********************************************/

body.open-menu {
    overflow-y: hidden;
}

//
.titanic {
    float: none;
}

// Remove blue highlight on click
* {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.page-template {
    .content-narrow-width {
        max-width: 770px;
        padding: 30px 3%;

        @include mappy-bp(mediumish){
            padding:65px 0 52px;
        }
    }

    h2.leadcopy {
        margin-bottom: 36px;
    }
}

/*******************************************/
// Main <section class="main" role="main">
.main {
    display: block;
    min-height: calc(100vh - #{$header-height-mobile + $footer-height-mobile});

    @include mappy-bp(mediumish){
        min-height: calc(100vh - #{$header-height-desktop + $footer-height-desktop});
    }
}

/*******************************************/
// Main <aside class="sidebar" role="complementary"> element
.sidebar {
    float: left;
    width: 100%;
    padding: 20px 15px 20px 15px;

    @include mappy-bp(mediumish){
        padding: 45px 0px 45px 0px;
    }
}

/*******************************************/
// Clear
.clear-after {
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

/*******************************************/
// flex
.flex {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;

    &.space-around {
        justify-content: space-around;
        @include mappy-bp(large){
            max-width: 1200px;
        }
    }
}

.flex.flex-start {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
    justify-content: flex-start;
}

/*******************************************/
// Content Max Width
.content-max-width {
    position: relative;
    width: 100%;
    padding: 0 6%;

    @include mappy-bp(mediumish){
        margin: 0 auto;
        max-width: 940px;
        padding: 0;
    }
    @include mappy-bp(large){
        max-width: 1170px;
    }
}

/*******************************************/
// Content Slider Width
.content-slider-width {
    position: relative;
    padding: 0;

    @include mappy-bp(mediumish){
        max-width: 940px;
        margin: 0 auto;
    }
    @include mappy-bp(large){
        max-width: 1170px;
    }
}

/*******************************************/
// Narrow Content Max Width
.content-narrow-width {
    position: relative;
    padding: 0;

    @include mappy-bp(mediumish){
        max-width: 740px;
        margin: 0 auto;
    }
}

/*******************************************/
// Breakpoints
.mob, .mobile {
    @include mappy-bp(mediumish){
        display: none !important;
    }
}

.desk, .desktop {
    @include mappy-bp(max-width mediumish){
        display: none !important;
    }
}

