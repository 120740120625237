/*********************************************
:: Styleguide
********************************************/
.page-styleguide {

    .styleguide-container {
        width: 100%;
        padding: 0 0 40px 0;

        h2.title {
            margin: 40px 0;
            font-size: 22px;
            color: #CCC;
            text-transform: uppercase;
            border-bottom: 2px solid #CCC;
        }

        .type {
            max-width: 600px;
            margin-bottom: 10px;
            border-bottom: 1px solid #CCC;

             p.note {
                margin: 0;
                font-size: 12px;
                font-weight: 300;

                strong {
                    margin-left: 10px;

                    &:first-of-type {
                        margin-left: 0;
                    }
                }
            }

            .button {
                margin-bottom: 20px;
            }
        }

        .col {
            width: 100%;
            padding: 40px 0;
        }

        .col.small {
            width: 100%;

            @include mappy-bp(mediumish){
                width: 300px;
            }

            .colour {
                margin: 0 0 20px;

                span {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background: $color-1;
                }
            }

            .colour.two span { background: $color-2 }
            .colour.three span { background: $color-3 }

            .colour.grey span { background: $color-grey }
            .colour.grey-light span { background: $color-light-grey }
            .colour.grey-medium span { background: $color-medium-grey }
            .colour.grey-dark span { background: $color-dark-grey }
        }

        .col.large {
            width: 100%;

            @include mappy-bp(mediumish){
                width: calc(100% - 300px);
                padding-left: 60px;
            }
        }

        .forms {
            width: 100%;
        }
    }
    /********************************************/

}
