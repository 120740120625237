/*********************************************
:: Title Module                    
********************************************/
.title-module{
	width: 100%;
	display: inline-block;
	padding: 15px 0;

	text-align: center;
	background: #f3f7f9;

	@include mappy-bp(mediumish){
		padding: 32px 0 31px;
	}

	h1 {
		margin: 0 auto;

		font-size: 25px;
		font-weight: 300;
		letter-spacing: 3px;
		line-height: 37px;
		text-transform: uppercase;
		color: $color-2;
	}

}
/********************************************/