/*********************************************
:: Products Search Summary
********************************************/
.page-search {

    .search-container {
        width: 100%;
        padding-top: 20px;

        @include mappy-bp(mediumish){
            padding: 40px 0 105px;
        }

        aside.filters {
            position: fixed;
            top: 0;
            left: -85%;

            float: left;
            width: 85%;
            height: 100%;
            padding: 20px;

            background-color: #f3f7f9;
            transition: all 0.4s cubic-bezier(0.63, 0.01, 0, 1.03);
            z-index: 9999;

            @include mappy-bp(mediumish){
                position: static;
                width: 270px;
                padding: 37px 35px;
            }

            &.open-menu {
                left: 0%;

                box-shadow: 0 0 12px 12px rgba(0, 0, 0, 0.2);
                overflow: scroll;
            }
        }

        .products {
            margin: auto;
            width: 100%;
            max-width: 420px;

            @include mappy-bp(700px mediumish){
                max-width: 640px;
            }

            @include mappy-bp(mediumish){
                float: right;
                max-width: calc(100% - 270px);
            }

            .products-wrapper {
                @include mappy-bp(mediumish){
                    padding-left: 126px;
                }
            }

            article {
                width: calc(50% - 20px);
                margin-left: 10px;
                margin-right: 10px;

                @include mappy-bp(medium){
                    width: calc(33.33% - 20px);
                }
                @include mappy-bp(mediumish){
                    width: calc(25% - 20px);
                }
            }

            .sort-area {
                width: 100px;
                float: left;
                padding: 0 0 19px 0;

                @include mappy-bp(mediumish){
                    float: right;
                }

                .selectric-wrapper {
                    width: 100px;
                    height: 32px;

                    .selectric {
                        width: 100px;
                        min-width: 100px;
                        height: 32px;

                        background: #fff;
                        border: 1px solid #cccccc;

                        b.button {
                            width: 0px;
                            height: 32px;
                            padding: 0 12px;

                            font-size: 0;
                            line-height: 30px;

                            border: none;

                            &:after {
                                font-size: 13px;
                                color: $color-1;
                            }
                        }

                        .label {
                            margin: 0 30px 0 10px;
                            line-height: 32px;
                            color: #8b8b8b;
                            font-family: $font-family-1;
                            font-size: 13px;
                            font-weight: $font-light;
                        }
                    }
                }

                p {
                    width: auto;
                    float: right;
                    font-size: 14px;
                    font-weight: 400;
                    color: #aaaaaa;
                    text-align: right;
                    margin: 4px 0 0 20px;
                }
            }

        }

        .filter-close {
            position: absolute;
            top: 18px;
            right: 30px;

            span {
                display: inline-block;
                font-size: 14px;
                font-weight: $font-bold;
                color: $color-2;
            }
        }

        .filter-trigger {
            float: right;
            margin-top: 5px;
            font-size: 15px;
            font-weight: $font-semibold;
            letter-spacing: 0.7px;
            text-transform: uppercase;
        }
    }
    /********************************************/

}
